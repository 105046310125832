@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.app-container {
  max-width: 1200px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.scroll {
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.m-scroll {
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 8s infinite linear;
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.m-scroll img {
  width: 65px;
  min-width: 65px;
  object-fit: contain;
  object-position: center;
}


@keyframes scrollText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}